<template>
	<search-list-table ref="searchListTable" :columns="columns" :formSearchList="formSearchList" :initDataSource="initDataSource">
		<template slot="btn">
			<a-button type="primary" @click="exportSheet">
				{{ $t('page.download') }}
			</a-button>
		</template>
		<template slot="action" slot-scope="{ columnData }">
			<a @click="Detail(columnData.loanId)">{{ $t('page.checkDetail') }}</a>
		</template>
	</search-list-table>
</template>
<script>
import { apiGetDisburse, apiFinanceData } from '@/api/loan'
import { apiBranchList } from '@/api/branch'
export default {
	data() {
		return {
			branchOfficeList: []
		}
	},
	computed: {
		formSearchList() {
			return [
				// {
				// 	label: 'table.applyInfo.cloums.caseId',
				// 	type: 'input',
				// 	validateField: ['caseId']
				// },
				{
					label: 'table.applyInfo.cloums.userName',
					type: 'input',
					validateField: ['name']
				},
				{
					label: 'table.CustomerManagement.fromdata.licensePlateNumber',
					type: 'input',
					validateField: ['registrationNumber']
				},
				{
					label: 'table.applyInfo.cloums.IdNumber',
					type: 'input',
					validateField: ['identityNum']
				},
				{
					label: 'table.applyInfo.cloums.caseStatus',
					type: 'select',
					validateField: ['loanStatuses'],
					selectOption: [1001, 1002, 1003, 1004, 1005, 1006, 1007, 1008, 1009, 1010, 1011, 1012],
					prefix: 'table.finance.loanStatusMap'
				},
				{
					label: 'Repeat Client',
					type: 'select',
					validateField: ['repeatClient'],
					selectOption: [
						{
							repName: 'No',
							value: '0'
						},
						{
							repName: 'Yes ',
							value: '1'
						}
					],
					dataPreType: {
						value: 'value',
						text: 'repName'
					}
				},
				{
					label: 'table.applyInfo.cloums.payTime',
					type: 'range-picker',
					validateField: ['applyTime', { initialValue: [] }]
				},
				{
					label: 'table.CustomerManagement.loanType',
					type: 'select',
					validateField: ['loanType'],
					selectOption: Array.from({ length: this.$loanMap.loanType.length }, (_, i) => i),
					prefix: 'table.applyInfo.financialLabel.loanMap'
				},
				{
					label: 'table.applyInfo.loanApplyLabel.branchOffice',
					type: 'select',
					validateField: ['officeId'],
					selectOption: this.branchOfficeList,
					dataPreType: {
						value: 'id',
						text: 'storeName'
					}
				},
				{
					label: 'Closed Date',
					type: 'range-picker',
					validateField: ['closeTime', { initialValue: [] }]
				}
			]
		},
		columns() {
			return [
				{
					// 案件ID
					title: 'No.',
					scopedSlots: { customRender: 'serial_number' },
					align: 'center',
					width: 80,
					fixed: 'left'
				},
				{
					// 案件ID
					title: this.$t('table.applyInfo.cloums.caseId'),
					dataIndex: 'loanId',
					align: 'center',
					width: 150,
					fixed: 'left',
					customRender: (v, o) => {
						return o.casePrefix + '-' + o.caseId
					}
				},
				{
					// 用户名
					title: this.$t('table.applyInfo.cloums.userName'),
					dataIndex: 'firstName',
					align: 'center',
					customRender: (v, o) => (v ? v : '') + ' ' + (o.middleName ? o.middleName : '') + ' ' + (o.lastName ? o.lastName : '')
				},
				{
					// 车牌号
					title: this.$t('table.CustomerManagement.fromdata.licensePlateNumber'),
					dataIndex: 'registrationNumbers',
					align: 'center',
					customRender: (v) => (v ? v.join('/') : '-')
				},
				{
					// 身份ID
					title: this.$t('table.applyInfo.cloums.IdNumber'),
					dataIndex: 'identityNum',
					align: 'center'
				},
				{
					// 申请金额
					title: this.$t('table.applyInfo.cloums.principalAmount'),
					dataIndex: 'applyAmount',
					align: 'center',
					scopedSlots: { customRender: 'money_format' }
				},
				{
					// 放款金额
					title: this.$t('table.applyInfo.cloums.putAmount'),
					dataIndex: 'disburseAmount',
					align: 'center',
					scopedSlots: { customRender: 'money_format' }
				},
				{
					// 保险金额
					title: 'IPF Amount',
					dataIndex: 'ipfAmount',
					align: 'center',
					scopedSlots: { customRender: 'money_format' }
				},
				{
					// 放款金额
					title: 'Total Amount',
					dataIndex: 'totalAmount',
					align: 'center',
					scopedSlots: { customRender: 'money_format' }
				},
				{
					// 支付时间
					title: this.$t('table.applyInfo.cloums.payTime'),
					dataIndex: 'disburseTime',
					align: 'center'
				},
				{
					title: this.$t('table.CustomerManagement.loanType'),
					dataIndex: 'loanType',
					align: 'center',
					customRender: (v) => this.$t(`table.applyInfo.financialLabel.loanMap.${v}`)
				},
				{
					// 案件状态
					title: this.$t('table.applyInfo.cloums.caseStatus'),
					dataIndex: 'loanStatus',
					align: 'center',
					customRender: (v, o) => {
						return this.$t(`table.finance.loanStatusMap.${v}`)
					}
				},
				{
					// 逾期天数
					title: this.$t('table.applyInfo.cloums.overdue'),
					dataIndex: 'dueDays',
					align: 'center'
				},
				{
					// 操作
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center',
					width: 100,
					fixed: 'right'
				}
			]
		}
	},
	methods: {
		initDataSource(initParams) {
			return apiGetDisburse(initParams)
		},
		Detail(id) {
			this.$router.push({
				path: 'lendingdetail',
				query: {
					id
				}
			})
		},
		exportSheet() {
			const searchForm = { ...this.$refs.searchListTable.searchFormTable.getFieldsValue() }
			searchForm.applyTimeBegin = searchForm.applyTime[0] && searchForm.applyTime[0].format('YYYY-MM-DD') + ' 00:00:00'
			searchForm.applyTimeEnd = searchForm.applyTime[1] && searchForm.applyTime[1].format('YYYY-MM-DD') + ' 23:59:59'
			searchForm.closeTimeBegin = searchForm.closeTime[0] && searchForm.closeTime[0].format('YYYY-MM-DD') + ' 00:00:00'
			searchForm.closeTimeEnd = searchForm.closeTime[1] && searchForm.closeTime[1].format('YYYY-MM-DD') + ' 23:59:59'
			delete searchForm.applyTime
			delete searchForm.closeTime
			apiFinanceData(searchForm).then((res) => {
				let str = `Case ID,Client Name,Date of Birth,National ID,Registration No.,Principal,IPF,Total,Loan Term,Loan Disbursement Date,Case Status,Date,`
				res.forEach((item) => {
					str += `\r\n${item.casePrefix}-${item.caseId},${item.fullName},${item.birthDay},${item.nationalId},${item.registrationNumbers ? item.registrationNumbers.join('/') : '-'},${
						item.applyAmount
					},${item.ipfAmount},${item.total},${item.loanTerm},${item.disburseDate},${this.$t(`table.finance.loanStatusMap.${item.caseStatus}`)},${item.disburseCheckPassTime},`
				})
				//encodeURIComponent解决中文乱码
				let uri = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(str)
				let link = document.createElement('a')
				link.href = uri
				link.download = `Loan Disbursed.csv`
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
			})
		}
	},
	created() {
		this.$nextTick(() => {
			apiBranchList().then((res) => {
				this.branchOfficeList = res
			})
		})
	}
}
</script>
<style scoped lang="less">
.from {
	.ant-input,
	.ant-input-number {
		width: 200px;
	}
	.ant-select {
		width: 400px;
	}
	.ant-form-item {
		margin-bottom: 10px;
	}
	.file {
		left: 0;
		top: 0;
		width: 100%;
		bottom: 0;
		position: absolute;
		opacity: 0;
		cursor: pointer;
	}
}
</style>
